
import { useState, useEffect } from "react";
import { getApiIntroduction } from "../../components/api-components/api-general";

import { config } from "../../Config";
import "./home-page.styles.css";

function HomePage() {

    const [ loading, setLoading ] = useState(true);
    const [ introduction, setIntroduction ] = useState(null);

    // On Page Load
    useEffect(() => {
        const loadData = async() => {
            try {
                const introResp = await getApiIntroduction(config.taekwondoApiBaseUrl);
                setIntroduction(introResp);
            }
            catch (error) {
                console.error("Error: Home.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
        console.log("Loaded Page Home-Page")
    }, [])

        return (
            <div className="home-page">
            {introduction ? (
                <div className="container">
                    <div className="home-page-header">Welcome to the TaeKwon-Do API Project</div>
                    <div className="home-page-content">{ introduction.About }</div>
                    <img className="tkd-chibi-icon" src="https://tkdapiimages.blob.core.windows.net/tkd-chibi-icons/11464606_honor_ethics_culture_values_respect.png" alt="chibi-tkd-icon"/>
                    <div className="home-page-content">More great content is coming, but for now check out the patterns page...</div>
                </div>
            ) : (
                <div className="container">
                <img className="tkd-chibi-icon" src="https://tkdapiimages.blob.core.windows.net/tkd-chibi-icons/11464606_honor_ethics_culture_values_respect.png" alt="chibi-tkd-icon"/>
                    <title className="home-page-tite">Site Under Construction</title>
                    <h1 className="home-page-header">Our Website is Under Construction</h1>
                    <p className="home-page-content">More great content is coming, but for now check out the patterns page...</p>
                </div>
            )}
            </div>
    );
};
export default HomePage;