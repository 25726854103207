import { useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import './tutorial-modal.styles.css';

function TutorialModal( {videoUrl, timestamp} ) {

    const [openModal, setOpenModal ] = useState(false);


    const onClickButton = e => {
        e.preventDefault()
        setOpenModal(true)
    }
  
    const onCloseModal = ()=> {
        setOpenModal(false)
    }

    if (!videoUrl) {
        // You can customize the rendering or return null if you don't want to render anything in this case
        return (
        <div className="movement-tutorial movement-key">No Tutorial</div>
        )
        }
    return(
        <div className="modal-container modal-tutorial">
            <button className="movement-tutorial movement-key"  onClick={onClickButton}>Play</button>
            <Modal open={openModal} onClose={onCloseModal}>
            <iframe className="tutorial-video" src={`${videoUrl}start=${timestamp}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </Modal>   
        </div>
    )
}
export default TutorialModal;