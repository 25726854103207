
export const getStances = async (baseUrl) => {
    try {
        const response = await fetch(`${baseUrl}/stances/`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }
        const data = response.json()
        return data
    }
    catch (error) {
        console.error("API call getStances failed: ", error);
    }
}

export const getStanceById = async (baseUrl, stanceId) => {
    try {
        const response = await fetch(`${baseUrl}/stances/id=${stanceId}`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }
        const data = response.json()
        return data
    }
    catch (error) {
        console.error("API call getStanceById failed ", error);
    }
}