import { useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import './obverse-reverse-modal.styles.css'

function ObvRevDetailModal( {obvrev} ) {

    const [openModal, setOpenModal ] = useState(false);

    const onClickButton = e => {
        e.preventDefault()
        setOpenModal(true);
    }
  
    const onCloseModal = () =>{
        setOpenModal(false);
    }

    if (!obvrev) {
        return (
            <div className="modal-container modal-obvrev">
                <div className="movement-obvrev movement-key">Obverse or Reverse
                    <div className="movement-obvrev movement-value">N/A</div>
                </div>
            </div>
        )
    }
    return (
        <div className="modal-container modal-obvrev">
            <div className="movement-obvrev movement-key"  onClick={onClickButton}>Obverse or Reverse
                <div className="movement-obvrev movement-value">{ obvrev }</div>
            </div>
            {/* <Modal open={openModal} onClose={onCloseModal}>
                <h1>{ obvrev.TechniqueName }</h1>
                <h2>({ technique.Terminology })</h2>
                <div className="technique-description-container">
                    <div className="technique-detail-key"> Description</div>
                    <div className="technique-detail-value">{ technique.Description }</div>
                </div>
            </Modal>    */}
        </div>
    )
}
export default ObvRevDetailModal;