import { useState, useEffect } from "react";
import { config } from "../../Config";

import { getPatternList, getPatternDetail, getPatternTutorial } from "../../components/api-components/api-patterns";
import PatternHeader from "../../components/pattern-header/pattern-header.component"
import PatternMovement from "../../components/pattern-movements/pattern-movements.component";

import "./pattern-page.styles.css";

function PatternPage() {

    const [ loading, setLoading ] = useState(true);
    const [ patternList, setPatternList ] = useState(null);
    const [ selectedPattern, setSelectedPattern ] = useState("Chon-Ji");
    const [ pattern, setPattern ] = useState(null);
    const [ patternTutorial, setPatternTutorial ] = useState(null);
    const [ showMovementDetail, setShowMovementDetail ] = useState("none");

    useEffect(() => {
        const loadData = async() => {
            try {
                const patternListResp = await getPatternList(config.taekwondoApiBaseUrl);
                setPatternList(patternListResp);

                const patternDetailResp = await getPatternDetail(config.taekwondoApiBaseUrl, selectedPattern);
                setPattern(patternDetailResp)

                const patternTutorialResp = await getPatternTutorial(config.taekwondoApiBaseUrl, selectedPattern);
                setPatternTutorial(patternTutorialResp);
            }
            catch (error) {
                console.error("Error: Patterns.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
    }, [])

    // Effect when selected pattern is changed.
    useEffect(() => {
        setLoading(true);
        const loadData = async() => {
            try {
                const patternDetailResp = await getPatternDetail(config.taekwondoApiBaseUrl, selectedPattern);
                setPattern(patternDetailResp)

                const patternTutorialResp = await getPatternTutorial(config.taekwondoApiBaseUrl, selectedPattern);
                setPatternTutorial(patternTutorialResp);
            }
            catch (error) {
                console.error("Error: Patterns.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
    }, [selectedPattern] )

    return (
        <div className="patterns-page">
            {patternList ? (
                <select className="pattern-dropdown" value={selectedPattern} onChange={(selection) => {
                const choice = selection.target.value;
                setSelectedPattern(choice);
                }}>
                {patternList.map((patternValue) => (
                    <option key={patternValue.id} value={patternValue.Name}>{patternValue.Name}</option>
                ))}
                </select>
            ) : (
            <select>This is loading...</select>
            )}
            
            {pattern ? (
            <>
                < PatternHeader pattern={ pattern }/>
                
                < PatternMovement 
                    startposition={ pattern.StartingPosition } 
                    endmovement={ pattern.EndMovement}
                    movements={ pattern.Movements } 
                    showMovementDetail={showMovementDetail} 
                    tutorial={patternTutorial}
                />
            </>
        ) : (
        <p>Loading...</p>
        )}
        </div>
    )
}
export default PatternPage;