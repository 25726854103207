import { useNavigate } from 'react-router-dom';

import "./techniques.styles.css";
import { useState } from 'react';

function TechniquesPage() {

    const navigate = useNavigate();

    const handleTechniqueClick = (techniqueType) => {
        console.log("handleTechniqueClick was clicked - variable:", techniqueType)
        // Example: Navigate to a new page using the technique name (or any other identifier)
        navigate(`/techniques/${techniqueType}`);
    };

    return(
        <div className="techniques-page">
            <div className="techniques-container">
                <div className="stances-container technique-choice" onClick={() => handleTechniqueClick("stances") }>Stances</div>
                <div className="hand-techniques-container technique-choice" onClick={() => handleTechniqueClick("attacktechniques") }>Hand Techniques</div>
                <div className="foot-techniques-container technique-choice">Foot Techniques</div>
                <div className="attack-techniques-container technique-choice">Attacking Techniques</div>
                <div className="defence-techniques-container technique-choice">Blocking Techniques</div>
            </div>
        </div>
    )
}
export default TechniquesPage;