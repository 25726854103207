

export const getPatternList = async (baseUrl) => {
    try {
        const response = await fetch(`${baseUrl}/pattern/`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const data = response.json();
        return data;
    }
    catch (error) {
        console.error("API Call api-Patterns.getPatternList failed: ", error);
    }
}

export const getPatternDetail = async (baseUrl, patternName) => {
    try{
        const response = await fetch(`${baseUrl}/pattern/name=${patternName}`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const data = response.json()
        return data
    }
    catch (error) {
        console.error("API Call api-Patterns.getPatternDetail failed: ", error);
    }
};

export const getPatternTutorial = async (baseUrl, patternName) =>{
    try {
        const response = await fetch(`${baseUrl}/patterntutorial/name=${patternName}`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const data = response.json()
        return data
    }
    catch (error) {
        console.error("API Call api-Patterns.getPatternTutorial failed: ", error);
    }
};