import { useState, useEffect } from "react";

import { getAttackingTechniques } from "../../../components/api-components/api-techniques";
import InformationCard from "../../../components/information-card/information-card.component";
import { config } from "../../../Config";

import "./attack-techniques.styles.css";

// Going to try see if we can build this in the first instance.

function AttackTechniquesPage() {
    const [ loading, setLoading ] = useState(true);
    const [ techniques, setTechniques ] = useState(null);

    // On Page Load
    useEffect(() => {
        const loadData = async() => {
            try {
                const techniquesResp = await getAttackingTechniques(config.taekwondoApiBaseUrl);
                setTechniques(techniquesResp);
            }
            catch (error) {
                console.error("Error: AttackTechniques.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
        console.log("Loaded Page Techniques-Page: ", techniques)
    }, [])

    return(
        <div className="techniques-page">
            { techniques ? (
                <div className="techniques-list">
                    { techniques.map((technique) => (
                        <InformationCard technique={technique} techniqueType={"alltechniques"} />
                    ))}
                </div>
            ) : (<p>Loading...</p>)}
        </div>
    )
}
export default AttackTechniquesPage