import { useState, useEffect } from "react";

import { getTerminologyList } from "../../components/api-components/api-general";
import { config } from "../../Config";

import "./terminology.styles.css";
import InformationCard from "../../components/information-card/information-card.component";
import SearchBox from "../../components/search-bar/search-bar.component";

function TerminologyPage() {
    const [ loading, setLoading ] = useState(true);
    const [ terminologyList, setTerminologyList ] = useState([]);
    const [ filteredTerminologyList, setFilteredTerminologyList ] = useState([]);
    const [ searchField, setSearchField ] = useState("");


    const onSearchChange = (event) => {
        const searchField = event.target.value.toLocaleLowerCase();
        setSearchField(searchField);
    };

    // On Page Load
    useEffect(() => {
        const loadData = async() => {
            try {
                const terminologyResp = await getTerminologyList(config.taekwondoApiBaseUrl);
                setTerminologyList(terminologyResp);
            }
            catch (error) {
                console.error("Error: Terminology.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
        // console.log("Loaded Page Terminology-Page: ", terminologyList)
    }, [])

    useEffect(() => {
        if (terminologyList.length > 0) {
            const myFilteredTechniques = terminologyList.filter((terminology) =>
                searchField === "" || terminology.Name.toLocaleLowerCase().includes(searchField)
            );
            console.log("Filtered results count:", myFilteredTechniques.length);
            setFilteredTerminologyList(myFilteredTechniques);
        }
    }, [terminologyList, searchField]);

    return(
        <div className="terminology-page">
            <SearchBox className="techniques" placeholder="Search for technique" onSearchChangeHandler={ onSearchChange } />
            {!loading ? (
                <div className="terminology-list">
                    <div className="terminology-headers">
                        <div className="terminology-name-header">Name</div>
                        <div className="terminology-name-header">Terminology</div>
                    </div>
                    <div className="terminology-values">
                        {filteredTerminologyList.map((terminology) => (
                            <div className="terminology-row">
                                <div className="terminology-value">{ terminology.Name }</div>
                                <div className="terminology-value">{ terminology.Terminology }</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (<p>Loading...</p>)}
        </div>
    )
}
export default TerminologyPage;