import { useState } from "react";

import "./pattern-movement.styles.css"

import StanceDetailModal from "../pattern-modals/stance-modal.component";
import ToolDetailModal from "../pattern-modals/tool-modal.component";
import SectionDetailModal from "../pattern-modals/section-modal.component";
import TutorialModal from "../pattern-modals/tutorial-modal.component";
import TechniqueDetailModal from "../pattern-modals/technique-modal.component";
import ObvRevDetailModal from "../pattern-modals/obverse-reverse-modal.component";
import MotionDetailModal from "../pattern-modals/motion-modal.component";

function PatternMovement( { startposition, endmovement, movements, tutorial } ) {

    const [ openMovement, setOpenMovement ] = useState(null);
    
    const toggleMovementDetail = (movementNumber) => {
        setOpenMovement((prevOpenMovement) => 
            prevOpenMovement === movementNumber ? null : movementNumber
        );
    };

    return(
        <div className="movement-list">
            <div className="pattern-start-container">
                <div className="pattern-start-movement">{ startposition }</div>
            </div>
            {movements.map((movement) => {
                return(
                    <div className={`movement-container ${ openMovement === movement.MovementNumber ? "open" : "" }`} key={movement.MovementNumber} onClick={() => toggleMovementDetail(movement.MovementNumber)}>
                        <div className="movement-number">{ movement.MovementNumber }.</div>
                        <div className="movement-description">{ movement.MovementInstruction }</div>
                        <div className="movement-detail">
                            <StanceDetailModal stance={ movement.Stance }/>
                            <TechniqueDetailModal technique={ movement.Technique }/>
                            <ToolDetailModal tool={ movement.Tool }/>
                            <SectionDetailModal section={ movement.Section }/>
                            <ObvRevDetailModal obvrev={ movement.ObverseOrReverse }/>
                            <MotionDetailModal motion={ movement.MotionType }/>
                        </div>
                        {tutorial && tutorial.MovementTimestamps && tutorial.MovementTimestamps[movement.MovementNumber - 1] && (
                        <div className="movement-tutorial">
                            < TutorialModal  videoUrl={tutorial.Source} timestamp={tutorial.MovementTimestamps[movement.MovementNumber -1].Timestamp} />
                        </div>
                        )}
                    </div>
                )
            })}
            <div className="pattern-end-container">
                <div className="pattern-end-movement">{ endmovement }</div>
            </div>
        </div>
    )
}
export default PatternMovement