
import './pattern-header.styles.css';

const PatternHeader = ({ pattern }) => {
    const { Name, NumberOfMovements, PatternMeaning, PatternDiagramPath, Grade, PatternNumber } = pattern;

    var beltPatternHeader = (Grade.IsBlackBelt)? "black-belt-pattern": "colour-belt-pattern"

    return (
    <div className="pattern-header-container">
        <div className={`pattern-header ${beltPatternHeader}`} key={ PatternNumber}>
            {/* <div className="pattern-title">{ Name }</div> */}
            <div className="pattern-meaning">{ PatternMeaning }</div>
            
            <div className="pattern-info-container">
                <div className="no-movements-container">
                    <div className="no-movements-header">Number of Movements</div>
                    <div className="no-movements-value">{ NumberOfMovements }</div>
                </div>
                <div className="pattern-diagram-container">
                    <div className="pattern-diagram-header">Pattern Diagram</div>
                    <img className={`pattern-diagram pattern-diagram-${Name}`} src={`https://tkdapiimages.blob.core.windows.net/patterndiagrams/${PatternDiagramPath}`} alt="Pattern Diagram"/>
                </div>
            </div>
        </div>
    </div>
    )
}
export default PatternHeader