
export const getApiIntroduction = async (baseUrl) => {
    try {
        console.log("getApiIntroduction is called");
        const response = await fetch(`${baseUrl}/home/`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const data = response.json();
        return data;
    }
    catch (error) {
        console.error("API Call api-general.getApiIntroduction failed: ", error);
    }
}

export const getTerminologyList = async (baseUrl) => {
    try {
        console.log("getTerminologyList is called");
        const response = await fetch(`${baseUrl}/terminology/`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const data = response.json();
        return data;
    }
    catch (error) {
        console.error("API Call api-general.getTerminologyList failed: ", error);
    }
}