import { useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import "./motion-modal.styles.css";

function MotionDetailModal( {motion} ) {

    const [ openModal, setOpenModal ] = useState(false);

    const onClickButton = e =>{
        e.preventDefault()
        setOpenModal(true)
    }
  
    const onCloseModal = ()=>{
        setOpenModal(false)
    }

    // console.log(motion)
    if (!motion) {
        return (
            <div className="modal-container modal-motion">
                <div className="movement-motion movement-key">Motion Type
                    <div className="movement-motion movement-value"></div>
                </div>
            </div>
        )
    }
    return (
        <div className="modal-container modal-motion">
            <div className="movement-motion movement-key">Motion Type
                <div className="movement-motion movement-value">{ motion }</div>
            </div>
        </div>
    )
}  
export default MotionDetailModal;