import { useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import './section-modal.styles.css'


function SectionDetailModal( {section} ) {

    const [ openModal, setOpenModal ] = useState(false);
  
    const onClickButton = e =>{
        e.preventDefault()
        setOpenModal(true)
    }
  
    const onCloseModal = ()=>{
        setOpenModal(false)
    }


    if (!section) {
        // You can customize the rendering or return null if you don't want to render anything in this case
        return (
            <div className="modal-container modal-section">
                <div className="movement-section movement-key">Section
                    <div className="movement-section movement-value">Not Applicable</div>
                </div>
            </div>
        )
    }
    return (
        <div className="modal-container modal-section">
            <div className="movement-section movement-key"  onClick={onClickButton}>Section
                <div className="movement-section movement-value">{ section.Section }</div>
            </div>
            <Modal open={openModal} onClose={onCloseModal}>
                <h1>{ section.Name }</h1>
                <h2>({ section.Terminology })</h2>
                <img className="section-diagram" src={`https://tkdapiimages.blob.core.windows.net/sectiondiagrams/${ section.DiagramUrl }`} alt=""/>
                <div className="section-description-container">
                    <div className="section-detail-key"> Description</div>
                    <div className="section-detail-value">{ section.Description }</div>
                </div>
            </Modal>   
        </div>
    )
}  
export default SectionDetailModal;