import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { getStanceById } from "../../../components/api-components/api-stances";
import { config } from "../../../Config";
import "./stances-detail.styles.css"

function StanceDetailsPage() {

    const { techniqueId } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ stance, setStance ] = useState(null);

    useEffect(() => {
        const loadData = async() => {
            try {
                const stanceResponse = await getStanceById(config.taekwondoApiBaseUrl, techniqueId);
                setStance(stanceResponse);
            }
            catch (error) {
                console.error("Error: StanceDetails.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
    },[]);

    return(
        <div>
            {!loading ? (
            <div className="stance-detail-container">
                <div className="stance-detail-headers">
                    <div className="stance-detail-name stance-headers">{ stance.Name } Stance</div>
                    <div className="stance-detail-terminology">{ stance.Terminology } Sogi</div>
                    <img className="stance-detail-diagram" src={`https://tkdapiimages.blob.core.windows.net/stancediagrams/${ stance.DiagramUrl }`} alt=""/>
                </div>
                <div className="stance-detail-info-container">
                    <div className="stance-detail-weightdist-container">
                        <div className="stance-weight-key stance-headers">Weight Distribution</div>
                        <div className="stance-weight-value">{ stance.WeightDistribution }</div>
                    </div>
                    {stance.LengthMeasuredFrom && stance.LengthMeasuredTo ? (
                    <div className="stance-detail-length-container">
                        <div className="stance-length-key stance-headers">Length Measured From</div>
                        <div className="stance-length-value">{ stance.LengthMeasuredFrom }</div>
                        <div className="stance-length-key stance-headers">Length Measured To</div>
                        <div className="stance-length-value">{ stance.LengthMeasuredTo }</div>
                    </div>
                    ) : (<div></div>)}
                    {stance.WidthMeasuredFrom && stance.WidthMeasuredTo ? (
                        <div className="stance-detail-width-container">
                            <div className="stance-width-key stance-headers">Width Measured From</div>
                            <div className="stance-width-value">{ stance.WidthMeasuredFrom }</div>
                            <div className="stance-width-key stance-headers">Width Measured To</div>
                            <div className="stance-width-value">{ stance.WidthMeasuredTo }</div>
                        </div>
                    ) : (<div></div>)}
                </div>
                <div className="stance-detail-header stance-headers">Description</div>
                <div className="stance-detail-description">{ stance.Description }</div>
            </div>
            ) : (<div>Loading...</div>)}
        </div>
    )
}
export default StanceDetailsPage;