import { useState, useEffect } from "react";

import SearchBox from "../../../components/search-bar/search-bar.component";
import { getStances } from "../../../components/api-components/api-stances";
import InformationCard from "../../../components/information-card/information-card.component";
import { config } from "../../../Config";

import "./stances-page.styles.css";

function StancesPage() {
    const [ loading, setLoading ] = useState(true);
    const [ stances, setStances ] = useState(null);

    // On Page Load
    useEffect(() => {
        const loadData = async() => {
            try {
                const stancesResp = await getStances(config.taekwondoApiBaseUrl);
                setStances(stancesResp);
            }
            catch (error) {
                console.error("Error: Patterns.Page - ", error)
            }
            finally {
                setLoading(false);
            }
        }
        loadData();
        console.log("Loaded Page Stances-Page: ", stances)
    }, [])

    // Want to do this somewhat like the moster rolodex - udemy course
    return(
        <div>
            {!loading ? (
                <div className="stances-list">
                    { stances.map((stance) => (
                        <InformationCard technique={stance} techniqueType={"stances"} titleSuffix={"Stance"}/>
                    ))}
                </div>
            ) : (<p>Loading...</p>)}
        </div>
    )
}
export default StancesPage