
export const getAttackingTechniques = async (baseUrl) => {
    try {
        const response = await fetch(`${baseUrl}/attackingTechniques/`)
        if(!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }
        const data = response.json()
        return data
    }
    catch (error) {
        console.error("API call getHandTechniques failed: ", error);
    }
}