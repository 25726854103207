import { useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import './technique-modal.styles.css'

function TechniqueDetailModal( {technique} ) {

    const [openModal, setOpenModal ] = useState(false);
    const [openDetail, setOpenDetail ] = useState(false); 
    const [ clickedState, setClickedState ] = useState("unclicked");

    const onClickButton = e => {
        e.preventDefault()
        setOpenModal(true);
    }
  
    const onCloseModal = () =>{
        setOpenModal(false);
    }

    const onClickReveal = () => {
        if(!openDetail){
            setOpenDetail(true);
            setClickedState("clicked");
        } else {
        setOpenDetail(false);
        setClickedState("unclicked");
        }
    }

    if (!technique) {
        return (
            <div className="modal-container modal-technique">
                <div className="movement-technique movement-key">Technique
                    <div className="movement-technique movement-value">No Technique</div>
                </div>
            </div>
        )
    }
    return (
        <div className="modal-container modal-technique">
            <div className="movement-technique movement-key"  onClick={onClickButton}>Technique
                <div className="movement-technique movement-value">{ technique.Name }</div>
            </div>
            <Modal open={openModal} onClose={onCloseModal}>
                <h1>{ technique.Name }</h1>
                <h2>({ technique.Terminology })</h2>
                <img className="technique-diagram" src={`https://tkdapiimages.blob.core.windows.net/techniquediagrams/${ technique.DiagramUrl }`} alt=""/>
                <div className="technique-description-container">
                    <div className="technique-detail-key"> Description</div>
                    <div className="technique-detail-value">{ technique.Description }</div>
                </div>
                <button className={`revealButton ${clickedState}`} onClick={onClickReveal}>{openDetail ? 'Hide Technique Type' : 'Show Technique Type'}</button>
                {openDetail && (
                <div className="technique-techniquetype-container">
                    <div className="techniquetype-detail-key">Technique Type</div>
                    <div className="techniquetype-detail-value">{ technique.TechniqueType.TechniqueTypeName } ({ technique.TechniqueType.Terminology })</div>
                    <div className="techniquetype-detail-key">Technique Type Description</div>
                    <div className="techniquetype-detail-value">{ technique.TechniqueType.Description }</div>
                </div>
                )}
            </Modal>   
        </div>
    )
}
export default TechniqueDetailModal;