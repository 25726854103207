
import "./navigation-bar.styles.css";
import { Outlet, Link } from "react-router-dom";

function  NavigationBar() {
    return (
        <div>
            <nav className="nav-bar-container">
                <Link className="nav-link nav-home" to="/">Home</Link>
                <Link className="nav-link nav-patterns" to="patterns">Patterns</Link>
                <Link className="nav-link nav-techniques" to="/techniques">Techniques</Link>
                <Link className="nav-link nav-stepsparring" to="/">Step Sparring</Link>
                <Link className="nav-link nav-terminology" to="/terminology">Terminology</Link>
                <div className="animation start-home"></div>
            </nav>
            <Outlet />
        </div>
    )
}
export default NavigationBar;