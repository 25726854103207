import { Routes, Route } from 'react-router-dom';

import NavigationBar from "./routes/navigation-bar/navigation-bar.component";
import HomePage from "./routes/home/home-page";
import PatternPage from "./routes/patterns/pattern-page";
import TechniquesPage from "./routes/techniques/techniques.page.jsx";
import StancesPage from "./routes/techniques/stances/stances-page.jsx";
import StanceDetailsPage from './routes/techniques/stances/stances-detail.page.jsx';

import AttackTechniquesPage from './routes/techniques/attack-techniques/attack-techniques.page.jsx';
import TerminologyPage from './routes/terminology/terminology.page.jsx';

import './App.css';


function App() {
  return (
    <Routes>
      <Route path="/" element={<NavigationBar />}>
        <Route index element={ <HomePage /> } />
        <Route path="patterns" element={<PatternPage / >} />

      {/* Techniques Section */}
        <Route path="techniques" element={<TechniquesPage / >} />
        <Route path="techniques/stances" element={<StancesPage />} />
        <Route path="techniques/stances/:techniqueId" element={<StanceDetailsPage />} />  
        <Route path="techniques/attacktechniques" element={<AttackTechniquesPage />} />  

        <Route path="terminology" element={<TerminologyPage />} /> 
      </Route>
    </Routes>
  );
}
export default App;
