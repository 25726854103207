import { useNavigate } from 'react-router-dom';

import "./information-card.styles.css";

function InformationCard({ technique, techniqueType, titlePrefix ="", titleSuffix="" }) {
  // console.log("information-card.component - technique: ", technique);
  const navigate = useNavigate();
  const cardTitle = `${titlePrefix} ${technique.Name} ${titleSuffix}`

  const handleCardClick = () => {
      // Example: Navigate to a new page using the technique name (or any other identifier)
      navigate(`/techniques/${techniqueType}/${technique.id}`);
  };


  return (
    <div className="information-card-container" onClick={handleCardClick}>
      {/* Check if technique and technique.Name exist before rendering */}
      {technique && technique.Name ? (
        <div className='information-card-content'>
          {/* <img src={technique.DiagramUrl} alt={`${technique.Name} Diagram`} /> */}
          { cardTitle.trim() }
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
}
export default InformationCard;
