import { useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import './tool-modal.styles.css'


function ToolDetailModal( {tool} ) {

    const [openModal, setOpenModal ] = useState(false);

    const onClickButton = e => {
        e.preventDefault()
        setOpenModal(true);
    }
  
    const onCloseModal = () =>{
        setOpenModal(false);
    }

    if (!tool) {
        // You can customize the rendering or return null if you don't want to render anything in this case
        return (
        <div className="modal-container modal-tool">
            <div className="movement-tool movement-key">Tool
                <div className="movement-tool movement-value">No Tool</div>
            </div>
        </div>
        )
    }
    return (
        <div className="modal-container modal-tool">
            <div className="movement-tool movement-key"  onClick={onClickButton}>Tool
                <div className="movement-tool movement-value">{ tool.Name }</div>
            </div>
            <Modal open={openModal} onClose={onCloseModal}>
                <h1>{ tool.Name }</h1>
                <h2>({ tool.Terminology })</h2>
                <img className="tool-diagram" src={`https://tkdapiimages.blob.core.windows.net/tooldiagrams/${ tool.DiagramUrl }`} alt=""/>
                <div className="tool-description-container">
                    <div className="tool-detail-key"> Description</div>
                    <div className="tool-detail-value">{ tool.Description }</div>
                </div>
                <div className="tool-detail-container tool-defence-container">
                    <div className="tool-detail-key">Used for Defence?</div>
                    <div className="tool-detail-value">{ String(tool.BlockingTool) }</div>
                </div>
                <div className="tool-detail-container tool-offence-container">
                    <div className="tool-detail-key">Used for Offence?</div>
                    <div className="tool-detail-value">{ String(tool.AttackingTool) }</div>
                </div>
            </Modal>   
        </div>
    )
}
export default ToolDetailModal;