import { useEffect, useState } from "react";

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import { config } from "../../Config";
import './stance-modal.styles.css'

function StanceDetailModal( {stance} ) {

    const [openModal, setOpenModal ] = useState(false);
  
    const onClickButton = e =>{
        e.preventDefault()
        setOpenModal(true);
    }
  
    const onCloseModal = () => {
        setOpenModal(false)
    }

    if (!stance) {
        // You can customize the rendering or return null if you don't want to render anything in this case
        return (
            <div className="modal-container modal-stance">
                <div className="movement-stance movement-key">Stance
                    <div className="movement-stance movement-value">No Stance</div>
                </div>
            </div>
        )
    }
    return (
        <div className="modal-container modal-stance">
            <div className="movement-stance movement-key"  onClick={onClickButton}>Stance
                <div className="movement-stance movement-value">{ stance.Name } stance</div>
            </div>
            <Modal open={openModal} onClose={onCloseModal}>
                <h1>{ stance.Name } Stance</h1>
                <h2>({ stance.Terminology } Sogi)</h2>
                <img className="stance-diagram" src={`${config.imagesBaseUrl}/stancediagrams/${ stance.DiagramUrl }`} alt=""/>
                <div className="stance-description-container">
                    <div className="stance-detail-key"> Description</div>
                    <div className="stance-detail-value">{ stance.Description }</div>
                </div>
                <div className="stance-information-container stance-weightdist-container">
                    <div className="stance-detail-key">Weight Distribution</div>
                    <div className="stance-detail-value">{ stance.WeightDistribution }</div>
                </div>
                <div className="stance-information-container stance-length-from-container">
                    <div className="stance-detail-key">Length Measured From</div>
                    <div className="stance-detail-value">{ stance.LengthMeasuredFrom }</div>
                </div>
                <div className="stance-information-container stance-length-to-container">
                    <div className="stance-detail-key">Length Measured To</div>
                    <div className="stance-detail-value">{ stance.LengthMeasuredTo }</div>
                </div>
                <div className="stance-information-container stance-width-measured-container">
                    <div className="stance-detail-key">Width Measured From</div>
                    <div className="stance-detail-value">{ stance.WidthMeasuredFrom }</div>
                </div>
                <div className="stance-information-container stance-width-to-container">
                    <div className="stance-detail-key">Width Measured To</div>
                    <div className="stance-detail-value">{ stance.WidthMeasuredTo }</div>
                </div>
            </Modal>   
        </div>
    )
}  
export default StanceDetailModal;